.outter_profile,.outter-img {
    height: 120px;
    margin: auto;
    overflow: hidden;
    width: 120px;
}


.outter_profile,.outter-img img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}